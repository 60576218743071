/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import Img from 'gatsby-image';
import React from 'react';
import MobileStoreButton from 'react-mobile-store-button';
import { graphql, StaticQuery } from 'gatsby';
import './index.css';
import { DefaultLayout } from '../../components/layout';
import { CaseBlock, Facts, Stage } from '../../components/molecules';

// https://itunes.apple.com/us/app/300meter/id1408620037?ls=1&mt=8

const meterQuery = graphql`
  {
    allStagesJson(filter: { siteTitle: { eq: "meter" } }) {
      edges {
        node {
          siteTitle
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            childImageSharp {
              fluid(maxWidth: 800, quality: 92) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageAlt
        }
      }
    }
    allImageSharp(filter: { fluid: { originalName: { regex: "/(see|300meter)/" } } }) {
      edges {
        node {
          id
          fluid(maxWidth: 800, quality: 92) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

const MeterCase = () => (
  <StaticQuery
    query={meterQuery}
    render={({ allStagesJson, allImageSharp }) => {
      const AndroidUrl = 'https://play.google.com/store/apps/details?id=ch.maseai.meter&hl=de';
      const iOSUrl = 'https://itunes.apple.com/us/app/300meter/id1408620037?ls=1&mt=8';
      const stageData = allStagesJson.edges[0].node;
      const appImg = allImageSharp.edges[2].node.fluid;
      const seeImg = allImageSharp.edges[3].node.fluid;

      return (
        <DefaultLayout>
          <Stage
            modifiers={['gradient', 'case']}
            image={{
              fluid: stageData.imageSrc.childImageSharp.fluid,
              alt: stageData.imageAlt,
            }}
            title={<h1 dangerouslySetInnerHTML={{ __html: stageData.title }} />}
          >
            <div>
              {stageData.contentBlocks.map((block) => (
                <p key={block.id} dangerouslySetInnerHTML={{ __html: block.value }} />
              ))}
            </div>
          </Stage>

          <CaseBlock
            title="300 Meter zum Ufer"
            subtitle="Hilfsmittel für Kapitäne, Sicherheitsabstand zum Ufer"
            graphic={<Img fluid={seeImg} alt="See" />}
          >
            <p>
              Wer gerne mit dem Boot auf Schweizer Seen unterwegs ist, der kennt das Problem: Es ist sehr schwierig, die
              Distanz zum Ufer richtig einzuschätzen.
            </p>
            <p>
              Innere Uferzone (0 bis 150 m): Motorschiffe dürfen die innere Uferzone nur befahren, um an- oder abzulegen,
              stillzuliegen (ankern) oder Engstellen zu durchfahren; sie haben dazu den kürzesten Weg zu nehmen.
              Parallelfahrten zum Ufer sind untersagt.
            </p>
            <p>
              Äussere Uferzone (150 bis 300 m): Parallelfahrten zum Ufer sind gestattet. Die maximal erlaubte Geschwindigkeit
              beträgt 10 km/h.
            </p>
          </CaseBlock>
          <div className="container">
            <Facts title="Technologie">
              <ul>
                <li>Geschrieben in Javascript</li>
                <li>Umsetzung mit React Native und Google Maps Platform</li>
                <li>Continuous Integration mit GitLab CI und Docker</li>
              </ul>
            </Facts>
          </div>
        </DefaultLayout>
      );
    }}
  />
);

export default MeterCase;

{
  /* <CaseBlock
title="Laden Sie die App herunter"
subtitle="Auf dem Appstore und dem Playstore"
graphic={<Img fluid={appImg} alt="300 Meter App" href="https://www.meetup.com/Bern-School-Of-Ai/" />}
>
<p>
  Die Applikation misst die Distanz zum Seeufer. Die Distanz wird mittels GPS Signal ermittelt. Die App
  funktioniert zur Zeit auf den folgenden Seen: Bielersee, Murtensee, Neuenburgersee, Thunersee, Brienzersee,
  Zugersee, Zürichsee, Genfersee und Vierwaldstättersee.
</p>
<div className="button-container">
  <MobileStoreButton
    className="app-button"
    store="ios"
    url={iOSUrl}
    linkProps={{ title: 'Android Store Button' }}
  />
  <MobileStoreButton
    className="app-button"
    store="android"
    url={AndroidUrl}
    linkProps={{ title: 'Android Store Button' }}
  />
</div>
</CaseBlock> */
}
